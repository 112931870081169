import { getContextualTargetingBaseUrl, getContextualTargetingUrl } from '../../chapter/lib/config/runtimeConfig'
import { apiErrorFromFetchResponse } from '../../chapter/api/fetcher'
import { wrapInApiErrorIfNecessary } from '../../chapter/lib/errors'

interface ContextualTargetingData {
    cohorts: string[]
    gam: string[]
    xandr: string[]
}

export const getContextualTargetingData = async (data: string): Promise<ContextualTargetingData> => {
    try {
        const controller = new AbortController()
        const timeoutId = setTimeout(() => controller.abort(), 2000)

        const url = `${getContextualTargetingBaseUrl()}${getContextualTargetingUrl()}`
        const response = await fetch(url, {
            headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
            method: 'POST',
            body: data,
        })
        clearTimeout(timeoutId)

        if (!response.ok) {
            // gracefully ignore json deserialization errors for error responses to get a proper ApiError
            const errorJson = (await response.json().catch(() => undefined)) as unknown
            throw apiErrorFromFetchResponse(undefined, response, errorJson, url)
        }

        return response.json() as Promise<ContextualTargetingData>
    } catch (error) {
        throw wrapInApiErrorIfNecessary(error)
    }
}
