import { getRestApiPath } from '@wh/common/chapter/lib/config/runtimeConfig'
import { storageAvailable } from '../../chapter/lib/storageAvailable'
import { DA_CONFIG_LAST_SUCCESSFUL_LOADED_VERSION } from '../../chapter/lib/localStorage'
import { fetcher } from '../../chapter/api/fetcher'

export const postCounterMetric = (metricName: string) => {
    return fetcher<void>(`/dac/advertisingmetrics/counter?metricName=${metricName}`, {
        method: 'POST',
        credentials: 'include',
    })
}

export const getDigitalAdvertisingConfigurationUrl = async (): Promise<string | undefined> => {
    try {
        let version = ''
        if (
            storageAvailable('localStorage') &&
            localStorage.getItem(DA_CONFIG_LAST_SUCCESSFUL_LOADED_VERSION) !== null &&
            localStorage.getItem(DA_CONFIG_LAST_SUCCESSFUL_LOADED_VERSION) !== ''
        ) {
            version = localStorage.getItem(DA_CONFIG_LAST_SUCCESSFUL_LOADED_VERSION) as string
        } else {
            version = await cacheConfigurationVersion()
        }

        return getConfigurationUrl(version)
    } catch (e) {
        console.log('Cannot load DA configuration version', e)
        return undefined
    }
}

export const cacheConfigurationVersion = async (): Promise<string> => {
    const version = await getConfigurationVersion()
    if (storageAvailable('localStorage')) {
        localStorage.setItem(DA_CONFIG_LAST_SUCCESSFUL_LOADED_VERSION, version)
    }
    return version
}

const getConfigurationVersion: () => Promise<string> = () =>
    fetcher<string>(
        `/dac/version/config/web`,
        {
            credentials: 'include',
            headers: {
                Accept: 'text/html',
            },
        },
        (response) => response.text(),
    )

const getConfigurationUrl = (version: string) => {
    return `${getRestApiPath()}/dac/config/web/${version}`
}
