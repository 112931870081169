import { useCallback, useContext, useEffect, useReducer } from 'react'
import { AdvertisingParametersV2 } from '@wh/common/digital-advertising/types/advertising-parametersV2'
import {
    cacheConfigurationVersion,
    getDigitalAdvertisingConfigurationUrl,
} from '@wh/common/digital-advertising/api/digitalAdvertisingApiClient'
import { DatapixelRenderSlotIds, LayoutSize, RenderSlotId, RenderSlotIdConfig } from '@wh/common/digital-advertising/config'
import { theme } from '@wh-components/core/theme'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { DebugFlagContext } from '@wh/common/chapter/components/DebugFlagProvider/DebugFlagProvider'
import { DmpEventName, DmpParameters, TrackCustomDmpEventFunction } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { DmpUserIdentities } from '../../chapter/components/DmpStateProvider/DmpStateProvider'
import { DidomiContext } from '../../chapter/components/Didomi/DidomiContext'
import {
    enrichAdvertisingParameters,
    enrichDmpParameters,
    loadPubmaticAndAdvertisingLibrary,
    requestAds,
} from './advertisingLibraryLoadingHelper'
import { useAdblockDetection } from '../../chapter/components/AdblockDetection/useAdblockDetection'
import { useContextualTargeting } from '../components/ContextualTargeting/useContextualTargeting'

declare global {
    interface Window {
        isAdvertisingLibraryInitAlreadyRequested?: boolean
        advertisingLibraryParameters?: AdvertisingLibraryParameters
        printMoneyJS?: AdvertisingLibrary
        matchMedia: (query: string) => MediaQueryList
    }
}

export type AdvertisingLibraryParameters = {
    platform?: string
    advertisingConfigApiUrl?: string
    layoutSize?: string
}

export type AdvertisingLibrary = {
    requestAdsWithKeywordsAndDivIds: (advertisingParametersV2: AdvertisingParametersV2, renderSlotIds: RenderSlotId[]) => void
    clearAdContainersWithIds: (renderSlotIds: RenderSlotId[]) => void
    trackPageViewEvent: (dmpParameters: DmpParameters) => void
    identifyUser: (dmpUserIdentities: DmpUserIdentities) => void
    trackCustomEvent: (eventName: DmpEventName, dmpParameters: DmpParameters) => void
    disableReloads: () => void
}

const defaultSpacing = 10
const skyscraperMinVisibilityWidth = 96
const desktopWithSkyscraperBreakingpoint = theme.breakpoints.desktop + defaultSpacing + skyscraperMinVisibilityWidth

const mediaQueries: { [K in LayoutSize]: string } = {
    PHONE: `only screen and (max-width: ${theme.breakpoints.tablet - 1}px)`,
    TABLET: `only screen and (min-width: ${theme.breakpoints.tablet}px) and (max-width: ${theme.breakpoints.desktop - 1}px)`,
    DESKTOP_WITHOUT_SKYSCRAPER: `only screen and (min-width: ${theme.breakpoints.desktop}px) and (max-width: ${
        desktopWithSkyscraperBreakingpoint - 1
    }px)`,
    DESKTOP_WITH_SKYSCRAPER: `only screen and (min-width: ${desktopWithSkyscraperBreakingpoint}px)`,
}

type RenderSlotsReducerState = {
    renderSlotIds: RenderSlotId[]
    layoutSize?: LayoutSize
}

type RenderSlotsReducerActionType = { type: 'update' }

export const useDigitalAdvertising = (
    renderSlotIdConfig: RenderSlotIdConfig,
    advertisingParametersV2: AdvertisingParametersV2 | undefined,
    dmpParameters: DmpParameters | undefined,
    dmpUserIdentities?: DmpUserIdentities,
    disableAdvertising: boolean = false,
) => {
    const debugFlags = useContext(DebugFlagContext)
    const advertisingState = useContext(AdvertisingStateContext)
    const { isReady } = useContext(DidomiContext)
    const resetAdvertisingState = advertisingState.onReset
    const setCurrentAdvertisingParameters = advertisingState.onSetCurrentAdvertisingParameters

    useContextualTargeting(dmpParameters)
    useAdblockDetection()
    // This reducer will trigger when a break point change happens.
    // The new layout size is determined and stored in the state.
    // We clear up all render slots that are not needed anymore and to retrieve the new needed render slot IDs.
    // The changed state will trigger requesting of the ads.
    // The reducer is wrapped in useCallback hook, since we do not want to define the reducer on each rendering,
    // only if the renderSlotIdConfig changes.
    const renderSlotsReducer = useCallback(
        (reducerState: RenderSlotsReducerState, action: RenderSlotsReducerActionType): RenderSlotsReducerState => {
            if (action.type === 'update') {
                const layoutSize = findCurrentLayoutSize()
                if (window.advertisingLibraryParameters) {
                    window.advertisingLibraryParameters.layoutSize = layoutSize?.toString()
                }

                // eslint-disable-next-line testing-library/render-result-naming-convention
                const previousRenderSlotIds = reducerState.renderSlotIds
                const newRenderSlotIds = getCurrentAdvertisingSlotIdsFromConfig(renderSlotIdConfig, layoutSize)

                const renderSlotIdsForClearUp = previousRenderSlotIds.filter((renderSlotId) => !newRenderSlotIds.includes(renderSlotId))
                clearRenderSlots(renderSlotIdsForClearUp)

                return { renderSlotIds: newRenderSlotIds, layoutSize: layoutSize }
            } else {
                throw new Error()
            }
        },
        [renderSlotIdConfig],
    )

    const [state, dispatch] = useReducer(renderSlotsReducer, { renderSlotIds: [] })

    // useEffect to add the reducer dispatch to the event listeners of the media queries.
    useEffect(() => {
        if (debugFlags.advertisingMode === 'disable' || disableAdvertising) {
            return
        }

        const dispatchUpdate = () => dispatch({ type: 'update' })

        dispatchUpdate()

        addEventListenersOnMediaQueries(dispatchUpdate)
        return () => {
            if (debugFlags.advertisingMode === 'disable') {
                return
            }

            return removeEventListenersOnMediaQueries(dispatchUpdate)
        }
    }, [debugFlags.advertisingMode, disableAdvertising, dispatch, renderSlotsReducer])

    // useEffect for loading the DA frontend library (if not already done)
    // and requesting of the render slots.
    // useEffect will report a warning if the *size* of the dependency list changes,
    // so we generate a string out of the render slot IDs.
    useEffect(() => {
        if (advertisingParametersV2 && state.layoutSize) {
            setCurrentAdvertisingParameters(enrichAdvertisingParameters(advertisingParametersV2, state.layoutSize))
        }

        if (debugFlags.advertisingMode === 'disable') {
            return
        }

        if (state.renderSlotIds.length && state.layoutSize && advertisingParametersV2) {
            loadLibraryAndFetchAds(
                state.renderSlotIds,
                state.layoutSize,
                advertisingParametersV2,
                dmpParameters,
                dmpUserIdentities,
                isReady,
            ).catch((e) => {
                // could be caused by ad blocker
                console.error(`DA: error on loading DA front end library / requesting the ads: ${e}`)
            })
        }

        // we always reload everything, therefore we can always reset the whole AdvertisingState atomically
        return () => {
            if (debugFlags.advertisingMode === 'disable') {
                return
            }

            clearRenderSlots(state.renderSlotIds)
            resetAdvertisingState()
        }

        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [
        debugFlags.advertisingMode,
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
        state.renderSlotIds.join(','),
        state.layoutSize,
        advertisingParametersV2,
        dmpParameters,
        resetAdvertisingState,
        isReady,
    ])

    const trackCustomDmpEvent: TrackCustomDmpEventFunction = useCallback(
        (eventName, additionalParameters) => {
            try {
                if (!window.printMoneyJS) {
                    console.error(`error tracking DMP event "${eventName}": window.printMoneyJS not loaded`)
                    return Promise.resolve()
                }

                if (!dmpParameters) {
                    console.error(`error tracking DMP event "${eventName}": dmpParameters undefined`)
                    return Promise.resolve()
                }

                if (!state.layoutSize) {
                    console.error(`error tracking DMP event "${eventName}": layoutSize undefined`)
                    return Promise.resolve()
                } else {
                    window.printMoneyJS.trackCustomEvent(eventName, {
                        ...enrichDmpParameters(dmpParameters, state.layoutSize),
                        ...additionalParameters,
                    })
                }

                // since printMoneyJS.trackCustomEvent does not return a Promise, but we need to wait for tracking to be finished before server routing in some cases, we introduce an artificial delay
                return promiseTimeout(CLICK_TAGGING_WORKAROUND_DELAY)
            } catch (error) {
                console.error(`error tracking DMP event "${eventName}":`, error)
                return Promise.resolve()
            }
        },
        [dmpParameters, state.layoutSize],
    )

    return {
        trackCustomDmpEvent,
    }
}

const clearRenderSlots = (renderSlotIds: RenderSlotId[]) => {
    if (window.printMoneyJS && renderSlotIds.length) {
        window.printMoneyJS.clearAdContainersWithIds(renderSlotIds)
    }
}

const addEventListenersOnMediaQueries = (action: () => void) =>
    (Object.keys(mediaQueries) as LayoutSize[]).forEach((layoutSize) => {
        const mediaQueryList = matchMediaOfLayoutSize(layoutSize)
        if (mediaQueryList.addEventListener) {
            mediaQueryList.addEventListener('change', action)
        } else {
            mediaQueryList.addListener(action)
        }
    })

const removeEventListenersOnMediaQueries = (action: () => void) =>
    (Object.keys(mediaQueries) as LayoutSize[]).forEach((layoutSize) => {
        const mediaQueryList = matchMediaOfLayoutSize(layoutSize)
        if (mediaQueryList.removeEventListener) {
            mediaQueryList.removeEventListener('change', action)
        } else {
            mediaQueryList.removeListener(action)
        }
    })

const getCurrentAdvertisingSlotIdsFromConfig = (renderSlotIdConfig: RenderSlotIdConfig, layoutSize?: LayoutSize) =>
    layoutSize ? [...renderSlotIdConfig[layoutSize], DatapixelRenderSlotIds[layoutSize]] : []

const findCurrentLayoutSize = () => {
    return (Object.keys(mediaQueries) as LayoutSize[]).find((layoutSize) => matchMediaOfLayoutSize(layoutSize).matches)
}

const matchMediaOfLayoutSize = (layoutSize: LayoutSize): MediaQueryList => {
    return window.matchMedia(mediaQueries[layoutSize])
}

let storyblokConfigAlreadyFetched: boolean = false

const loadLibraryAndFetchAds = async (
    renderSlotIds: RenderSlotId[],
    layoutSize: LayoutSize,
    advertisingParametersV2: AdvertisingParametersV2,
    dmpParameters: DmpParameters | undefined,
    dmpUserIdentities: DmpUserIdentities | undefined,
    didomiIsReady: boolean,
) => {
    if (window.isAdvertisingLibraryInitAlreadyRequested) {
        requestAds(renderSlotIds, layoutSize, advertisingParametersV2, dmpParameters)
    } else {
        await setWindowObjects(layoutSize)
        if (didomiIsReady) {
            await loadPubmaticAndAdvertisingLibrary(renderSlotIds, layoutSize, advertisingParametersV2, dmpParameters, dmpUserIdentities)
        }
        if (!storyblokConfigAlreadyFetched) {
            storyblokConfigAlreadyFetched = true
            await cacheConfigurationVersion()
        }
    }
}

const setWindowObjects = async (layoutSize: LayoutSize) => {
    window.advertisingLibraryParameters = {
        platform: 'RESPONSIVE_WEB',
        advertisingConfigApiUrl: await getDigitalAdvertisingConfigurationUrl(),
        layoutSize: layoutSize.toString(),
    }
}

const CLICK_TAGGING_WORKAROUND_DELAY = 200
const promiseTimeout = (time: number): Promise<void> => {
    return new Promise((resolve, _reject) => {
        setTimeout(() => {
            resolve()
        }, time)
    })
}
