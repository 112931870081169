import { AdvertisingParametersV2 } from '@wh/common/digital-advertising/types/advertising-parametersV2'
import { loadExternalScriptAsync } from '@wh/common/chapter/lib/externalScriptLoader'
import { LayoutSize, RenderSlotId } from '@wh/common/digital-advertising/config'
import { DmpParameters } from '@wh/common/chapter/components/DmpStateProvider/DmpStateProvider'
import { DmpUserIdentities } from '../../chapter/components/DmpStateProvider/DmpStateProvider'
import { getFrontendLibraryUrl } from '../../chapter/lib/config/runtimeConfig'

export const loadPubmaticAndAdvertisingLibrary = async (
    renderSlotIds: RenderSlotId[],
    layoutSize: LayoutSize,
    advertisingParametersV2: AdvertisingParametersV2,
    dmpParameters: DmpParameters | undefined,
    dmpUserIdentities: DmpUserIdentities | undefined,
) => {
    const purl = window.location.href
    const url = '//ads.pubmatic.com/AdServer/js/pwt/156375/7445'
    let profileVersionId = ''
    // this is to activate a debug profile, which then allows u to see some stuff in the console
    if (purl.indexOf('pwtv=') > 0) {
        const regexp = /pwtv=(.*?)(&|$)/g
        const matches = regexp.exec(purl)
        // @ts-ignore
        if (matches.length >= 2 && matches[1].length > 0) {
            // @ts-ignore
            profileVersionId = `/${matches[1]}`
        }
    }

    // in case pubmatic takes longer than 150ms to load, load the frontendlibrary
    const timeoutId = window.setTimeout(
        loadFrontendLibrary,
        150,
        renderSlotIds,
        layoutSize,
        advertisingParametersV2,
        dmpParameters,
        dmpUserIdentities,
    )

    loadExternalScriptAsync(`${url + profileVersionId}/pwt.js`)
        .catch(() => {
            console.error('DA: Pubmatic failed to load')
        })
        .finally(() => {
            // if pubmatic was fast enough (<150ms) delete the timeout and proceed regularly
            window.clearTimeout(timeoutId)
            // load the frontend library no matter whether pubmatic was successful or not
            loadFrontendLibrary(renderSlotIds, layoutSize, advertisingParametersV2, dmpParameters, dmpUserIdentities)
        })
}

export const loadFrontendLibrary = async (
    renderSlotIds: RenderSlotId[],
    layoutSize: LayoutSize,
    advertisingParametersV2: AdvertisingParametersV2,
    dmpParameters: DmpParameters | undefined,
    dmpUserIdentities: DmpUserIdentities | undefined,
) => {
    if (!window.isAdvertisingLibraryInitAlreadyRequested) {
        window.isAdvertisingLibraryInitAlreadyRequested = true

        const libraryUrl = getFrontendLibraryUrl()
        await loadExternalScriptAsync(libraryUrl)

        if (window.printMoneyJS) {
            // it is enough to identify the user once at the initial page load
            dmpUserIdentities && window.printMoneyJS.identifyUser(dmpUserIdentities)
        }
        requestAds(renderSlotIds, layoutSize, advertisingParametersV2, dmpParameters)
    }
}

export const requestAds = (
    renderSlotIds: RenderSlotId[],
    layoutSize: LayoutSize,
    advertisingParametersV2: AdvertisingParametersV2,
    dmpParameters: DmpParameters | undefined,
) => {
    if (window.printMoneyJS && renderSlotIds.length) {
        window.printMoneyJS.requestAdsWithKeywordsAndDivIds(enrichAdvertisingParameters(advertisingParametersV2, layoutSize), renderSlotIds)
        if (layoutSize === 'PHONE') {
            window.printMoneyJS.disableReloads()
        }
        dmpParameters && window.printMoneyJS.trackPageViewEvent(enrichDmpParameters(dmpParameters, layoutSize))
    }
}

export const enrichAdvertisingParameters = (advertisingParameters: AdvertisingParametersV2, layoutSize: LayoutSize) => {
    return {
        ...advertisingParameters,
        ...{ device: [layoutSizeToDevice[layoutSize]] },
    }
}
export const enrichDmpParameters = (dmpParameters: DmpParameters, layoutSize: LayoutSize) => {
    return {
        ...dmpParameters,
        ...{ device: layoutSizeToDevice[layoutSize] },
        platform: 'RESPONSIVE_WEB',
    }
}
const layoutSizeToDevice: Record<LayoutSize, string> = {
    PHONE: 'phone',
    TABLET: 'tablet',
    DESKTOP_WITHOUT_SKYSCRAPER: 'desktop',
    DESKTOP_WITH_SKYSCRAPER: 'desktop',
}
